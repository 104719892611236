import React from 'react'
import Page from '../page'
import { graphql } from 'gatsby'

export default (props) => <Page {...props} />

export const pageQuery = graphql`
  query PageQueryEn {
    allContentfulPage(filter: { node_locale: { eq: "en" } }) {
    edges {
      node {
        ...contentfulPage
      }
    }
  }
}
`
